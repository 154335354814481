@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@use '@sparbanken-syd/sparbanken-syd-theme/variables' as var;
@use '@angular/material' as mat;
@use 'variables' as local_var;

$t: mat.m2-define-typography-config($font-family: 'Titillium Web',
$headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
$headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
$headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
$headline-4: mat.m2-define-typography-level(34px, 40px, 400, 'Titillium Web', normal),
$headline-5: mat.m2-define-typography-level(24px, 32px, 400, 'Titillium Web', normal),
$headline-6: mat.m2-define-typography-level(22px, 30px, 600, 'Titillium Web', normal),
$subtitle-1: mat.m2-define-typography-level(18px, 23.2px, 600, 'Titillium Web', normal),
$subtitle-2: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-1: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$body-2: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal),
$caption: mat.m2-define-typography-level(12px, 18px, 400, 'Titillium Web', normal),
$button: mat.m2-define-typography-level(16px, 22px, 400, 'Titillium Web', normal));

// Headline-5: H1, Headline-6: h2, Subtitle-1: h3, Subtitle-2: h4, Body-1: Bold P, Body-2: P

@include spb.set-typography($t);


html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images. */
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: local_var.$spb-max-width;
  width: 100%;
}

@media screen and (width >= 400px) {
  .spb-plain-content {
    // border: 1px solid red;
    // width: 95%;
  }

  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: local_var.$spb-max-width) and (min-width: local_var.$spb-small) {
  .spb-plain-content {
    // border: 3px solid dodgerblue;
    // width: 95%;
  }

  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (width <= 1215px) and (width >= 400px) {
  .spb-plain-content {
    // border: 3px solid dodgerblue;
    // width: 95%;
  }

  .spb-plain-content > * {
    padding-left: 65px;
    padding-right: 45px;
  }
}


@media screen and (width <= 400px) {
  .spb-plain-content {
    // border: 5px solid lawngreen;
  }

  .spb-plain-content > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}


.spb-error {
  background-color: var.$spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 0; // So that IE realizes we actually have a height
  min-height: 100%;
}

h1,
h2,
h3 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

h4 {
  margin-bottom: 7px !important;
  margin-top: 20px !important;
}

/*
Header in tables
*/
th {
  color: #808080 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}


a {
  color: #232323;
}

